/* @media screen and (min-width: 601px) { */
/* @media (-webkit-max-device-pixel-ratio: 1) { */

div.Container{
    position: relative;
    /* border: 4px solid brown; */
    height: 100%;
    overflow: auto;

    div.ToppingsDescription{
        font-size: 14px;
        margin-bottom: 5px;
    }
    
    div.MaxToppingsError{
        font-size: 12px;
        color: #FF0000;
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        top: -50px;
    }

    div.PizzaContainer{
        /* border: 2px solid green; */
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        div.RightSideTabs{
            display: flex;
            flex-direction: column;
            direction: ltr;
            position: absolute;
            right: 0;
            top: 0;
            max-height: 100%;
            overflow: auto;
            font-size: 12px;
            width: 110px;
            z-index: 20;

            div.SelectedProductOption{
                text-align: right;
                color: #FFF;
                border-radius: 20px 0 0 20px;
                padding-left: 13px;
                padding-right: 13px;
            }

            div.SelectedToppings{
                display: flex;
                flex-direction: column;
                
                div.ToppingToEdit{
                    color: #FFF;
                    display: flex;
                    border-radius: 20px 0 0 20px;
                    justify-content: space-between;
                    align-items: center;
                    cursor: default;
                    
                    div.ToppingNameAndPrice{
                        text-align: right;

                        div.ToppingName{
                            width: 60px;
                            overflow: hidden !important;
                            text-overflow: ellipsis !important;

                            span.ToppingXRemover{
                                padding-left: 3px;
                                cursor: pointer;
                            }
    
                            span.ToppingXRemover:hover{
                            }
                        }
                        div.ToppingPrice{
                            padding-right: 13px;
                        }
                    }

                    div.ToppingImgPositions{
                    }
                }

                div.ToppingToEdit:hover{
                    /* cursor: default; */
                    /* background-color: rgba(255,0,0,0.5) !important; */
                }

                
            }

            div.SelectedToppings > * + * {
                margin-top: 5px;
            }
        }

        div.RightSideTabs > * + *{
            margin-top: 5px;
        }

        div.PizzaIcon{
            /* border: 1px solid red; */
            position: relative;

            div.PizzaCenter{
                /* background-color: rgb(0,0,255); */
                /* border: 4px solid black; */
                z-index: 11;
                position: absolute;
                border-radius: 50%;
                height: 28px;
                width: 30px;
                top: 102px;
                right: 100px;
            }

            div.TopLeftQuarter{
                /* background-color: rgba(123,114,23,0.5); */
                z-index: 10;
                position: absolute;
                padding: 55px;
                border-top-left-radius: 170px;
                top: 0;
                left: 0;

                img{
                    transform: rotate(-90deg);
                    position: absolute;
                    width: 112px;
                    border-top-right-radius: 170px;
                    top: 4px;
                    left: 0;
                }
            }
            div.TopRightQuarter{
                /* background-color: rgba(123,114,23,0.5); */
                z-index: 10;
                position: absolute;
                padding: 55px;
                border-top-right-radius: 170px;
                top: 0;
                right: 0;
                img{
                    position: absolute;
                    width: 112px;
                    border-top-right-radius: 170px;
                    top: 0;
                    right: 0;
                }
            }
            div.BottomLeftQuarter{
                /* background-color: rgba(123,114,23,0.5); */
                z-index: 10;
                position: absolute;
                padding: 55px;
                border-bottom-left-radius: 170px;
                bottom: 7px;
                left: 0;
                img{
                    transform: rotate(180deg);
                    position: absolute;
                    width: 112px;
                    border-top-right-radius: 170px;
                    bottom: 4px;
                    left: 0;
                }
            }
            div.BottomRightQuarter{
                /* background-color: rgba(123,114,23,0.5); */
                z-index: 10;
                position: absolute;
                padding: 55px;
                border-bottom-right-radius: 170px;
                bottom: 7px;
                right: 0;

                img{
                    transform: rotate(90deg);
                    position: absolute;
                    width: 112px;
                    border-top-right-radius: 170px;
                    bottom: 4px;
                    right: 0;
                }
            }
        }

        div.ChoiceIcon{
            /* border: 1px solid blue; */
            position: absolute;
        }
    }

    div.ItemToppingList{
        /* border: 1px solid blue; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 15% 0 15%;

        div.ItemTopping{
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            font-size: 15px;
        }
    }

    div.ItemToppingList > * + *{
        margin-top: 10px;
    }
}
/* } */

