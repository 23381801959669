.container {
  /* border: 2px solid red; */
  display: flex;
  flex-flow: column nowrap;
  /* justify-content: center; */
  align-items: center;
  height: 100vh;
}
.Header {
  text-align: center;
  color: var(--mainWhite);
  font-size: 2em;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
    div.container {
      width: 90vw;
      display: flex;
      flex-flow: column nowrap;
    }
  }
  