div.ModalContainer{
    /* margin: 0;
    padding: 0;
    background: url(../../../assets/welcome_bg.png) no-repeat;
    background-size: 100%;
    background-position: bottom;
    background-color: #FFFF;
    min-height: 100%; */



    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 18px; */

    h3{
        text-align: center;
        width: 80%;
        font-size: 23px;
    }

    button{
        margin-top: 18px;
        font-family: Heebo;
        outline: none;
        /* border: none; */
        width: 185px;
        height: 45px;
        /* border: 1px solid #00b1e1; */
        border-radius: 25px;
        font-size: 18px;
        align-text: center;
        font-weight: 600;
        /* color: #00b1e1; */
        background-color: unset;
        cursor: pointer;
    }

    h3{
        text-overflow: ellipsis;
    }
}