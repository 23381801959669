.container{
  /* border: 4px solid blue; */
  overflow: hidden;
  width: 99vw;
  height: 100%;
  /* height: 100vh; */
  /* display: flex; */
  /* max-height: 60%; */
    /* max-height: 100%; */
}
.TopDiv {
  /* border: 8px solid purple; */
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 5px;
    overflow: hidden;
    height: 100%;
}
.HistList {
  /* border: 5px solid pink; */

  margin-left: 10px;
  height: 100%;
  overflow: auto;
  flex: 4 4;
  flex-flow: column nowrap;
  padding-bottom: 50px;

}
.contBasket {
  /* border: 6px solid orange; */
  flex: 2 2;
  /* overflow: hidden; */
  height: 100%;
}

.Basket {
  /* border: 3px solid green; */
  display: flex;
  height: 100%;
  flex-flow: column nowrap;
  background-color: var(--mainWhite);
  border-radius: var(--brdRadius);

  position: relative;
}
.closeBasket {
  width: max-content;
  height: max-content;
  position: absolute;
  top:0;
  left:0;
  padding-left: 10px;
  padding-top: 5px;
  cursor: pointer;
  font-size: 25px;
}
@media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column nowrap;
    }
  }