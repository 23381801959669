.container{
    direction: ltr;
    margin-bottom: 5vh;
    direction: rtl;
    .closeBtn{
        /* border: 1px solid red; */
        margin: 0 .7vw  0 .6vw;
        font-size: 2vw;
        cursor: pointer;
        width: min-content;
        text-align: right;
    }

    .body{
        /* border: 1px solid red; */
        margin: auto;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .itemImage{
            width: 20%;
        }

        .itemName{
            margin-top: 1vh;
            font-size: 1.9vw;
            font-weight: 600;
        }

        .details{
            /* border: 1px solid red; */
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            flex-wrap: wrap;
            
            .detail{
                /* border: 1px solid red; */
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                margin: 1vw;

                .detailTitle{
                    font-size: 1.3vw;
                    font-weight: 600;
                    text-decoration: underline;
                }
                .detailContent{
                    font-size: 1.2vw;
                }
            }
        }

        .comments{
            /* border: 1px solid red; */
            width: 100%;
            margin-top: 5vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .titleComments{
                font-size: 1.3vw;
                font-weight: 600;
                text-decoration: underline;
            }

            .bodyComments{
                font-size: 1.2vw;
                width: 80%;
                word-wrap: break-word;
                text-align: center;
                height: 90px;
                overflow: auto;
            }
        }
    }
}

@media (max-width: 700px){
    .container{
        .closeBtn{
            font-size: 4.5vw;
            margin: 0 1.2vw  0 .6vw;
        }

        .body{
            width: 90%;
            .itemName{
                font-size: 4.2vw;
            }
            .details{
                .detail{
                    .detailTitle{
                        font-size: 2.7vw;
                    }
                    .detailContent{
                        font-size: 2.5vw;
                    }
                }
            }

            .comments{
                .titleComments{
                    font-size: 2.7vw;
                }
                .bodyComments{
                    height: 70px;
                    overflow: auto;
                    font-size: 2.5vw;
                }
            }
        }

    }
}