@media screen and (min-width: 701px) {
  div.BackContainer{
    justify-self: right;
    /* flex-flow: column nowrap; */
    text-align: right;
    padding: 5px;
    padding-right: 115px;
    height: 77vh;
    max-height: 77vh;
    margin-top: 5px;
    border-radius: var(--brdRadius) 0 0 var(--brdRadius);
    /* width: min-content; */
    /* min-width: 100px; */
    /* overflow-y: auto; */
    position: absolute;
    right: 0;
    /* opacity: .4; */
    background-color: var(--mainColor);
    color: #fff;
    font-size: 20px;
    font-weight: 0;

    overflow: auto;

    div.SubcatName {
      cursor: pointer;
      font-weight: 10;
      margin: 10px;
      font-size: 21px;
      border-bottom: .07vw solid #fff;
    }


  }
  
  div.Container{
    direction: ltr; /*position scrollbar on the right hand side*/

    justify-self: right;
    flex-flow: column nowrap;
    height: 77vh;
    max-height: 77vh;
    margin-top: 5px;
    overflow-x: auto;
    border-radius: var(--brdRadius) 0 0 var(--brdRadius);
    background-color: var(--mainColor);
    
    z-index: 1;



    div.TopDiv {
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-around;
      max-width: 100px;
      padding: 20px;
      margin: 5px;
    }
  }
}



/* 
backup for top code ^^

div.Container{
  justify-self: right;
  flex-flow: column nowrap;
  height: 77vh;
  max-height: 77vh;
  margin-top: 5px;
  overflow-x: auto;
  border-radius: var(--brdRadius) 0 0 var(--brdRadius);
  background-color: var(--mainColor);
  position: relative;

  div.TopDiv {
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    max-width: 100px;
    padding: 20px;
    margin: 5px;
  }
} */



@media screen and (max-width: 700px) {
  div.Container{
    position: fixed;
    bottom: 0;
    width: 100vw;
    /* border-radius: 8px 8px 0 0; */
    background-color: var(--mainColor);
    display: flex;
    justify-content: center;
    overflow: auto;

    div.TopDiv {
      margin-top: 5px;
      display: flex;
      /* gap: 15px; */
      /* margin-left: 8px; */
      /* margin-right: 8px; */
      overflow: auto;
      scrollbar-height: 3px;
    }

    div.TopDiv > *{
      padding-left: 12px;
    }

    div.TopDiv:nth-child(1){
      padding-right: 12px;
    }

    div.TopDiv::-webkit-scrollbar{
      height: 3px;
    }
  }


}


@media (max-height: 300px) {
  div.Container{
    height: 0;
  }
}