.SocialIcons * + *{
    margin-right: 20px;
}

div.Container{
    align-self: center;

    div.AppTopMenu {
        text-align: center;
        display: flex;
        flex-flow: row-reverse wrap;
        justify-content: space-around;
        margin: 5px;

        display: flex;
        justify-content: center;
        align-items: center;

        /* div.SearchContainer{
            border: 2px solid blue;
            margin-right: 2vw;
        } */
        
    }
    
    div.menubtn {
        padding: 10px;
        background-color: transparent;
        border: 0px;
        color: var(--mainWhite);
        font-size: 24px;
        font-weight: 400;
        cursor: pointer;
    
        &.menubtnActive {
        text-decoration: underline var(--mainWhite);
        }
    }
    input.searchbox {
        background: no-repeat right;
        background-size: 20px;
        width: 200px;
        cursor: pointer;
        border: 1px solid var(--mainWhite);
        border-radius: 31px;
        color: black;
    }
}