div.ModalContainer{
    width: 100%;

    div.CloseBtn{
        text-align: right;
        color: #808080;
        font-weight: 700;
        font-size:23px;
        
        span:hover{
            cursor: pointer;
        }
    }
    
    div.Content{
        height: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        div.BodyContent{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            color: #000;
            font-size: 22px;
            
            :nth-child(3){
                font-weight: 600;
            }
            :nth-child(4){
                span{
                    font-weight: 600;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        div.FootContent{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            font-size: 12px;

            :nth-child(1){
                text-decoration: underline;
            }
        }
    }
}
