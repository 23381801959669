.container{
  /* border: 6px solid red; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-self: center; */
  height: 100%;
  width: 100%;
}
.TopDiv {
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 100%;
    margin-top: 15px;
    padding-bottom: 10px;
}

.OrderNumber{
  font-size: calc(.2vw + 14px);
  font-weight: 600; 
}

.List {
  /* border: 6px solid red; */
  overflow: auto;
  height: 100%;
  margin-top: 35px;
  padding: 0 15px 0 15px;
  flex: 4;
}

.ButtonWrapper{
  /* border: 6px solid blue; */
  height: 15%;
  display: flex;
  justify-content: center;
}
.button {
  cursor: pointer;
  align-self: center;
  width: 50%;
  /* margin: 30px 0 30px 0; */
  border-radius: 20px;
  font-size: calc(.2vw + 12px);

}

/* @media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column nowrap;
    }
  } */
