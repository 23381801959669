div.Container{
    margin-right: 10px;
    color: #FFF;
    div.InputField{
        /* width: max-content; */

        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        border: 2px solid #FFF;
        border-radius: 20px;
        padding: 6px;

        transition: width .6s;
    }

 
}

    /* position: relative;

    div.Suggestions{
        position: absolute;
        top:30px;
        left: 0;
        right: 0;
        color: #000;
        z-index: 1;
        max-height: 60vh;
        overflow: auto;
        background-color: rgba(0,0,0,.4);

        display: flex;
        flex-direction: column;
        align-items: center;
        
        div.Suggestion{
            background-color: #FFF;
            border-radius: 10px;
            width: 90%;
        }
    }

    div.Suggestions > * + *{
        margin-top: 1vh;
    }

     */
